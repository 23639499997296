var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rooms.length > 0
        ? _c(
            "vx-card",
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-full sm:flex-row flex-col my-4 gap-2 flex items-center justify-between",
                },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("questionnaire_title")) +
                        " " +
                        _vm._s(
                          _vm.firstConfig && _vm.firstConfig.questionnaire.id
                        ) +
                        ' - "'
                    ),
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.firstConfig && _vm.firstConfig.questionnaire.name
                        )
                      ),
                    ]),
                    _vm._v('" '),
                  ]),
                  _c("div", {
                    staticClass: "w-10 h-10 loading-element block relative",
                  }),
                  _c(
                    "div",
                    { staticClass: "flex flex-row gap-2 items-center" },
                    [
                      _c("label", { staticClass: "text-sm sm:text-base" }, [
                        _vm._v(_vm._s(_vm.$t("todas"))),
                      ]),
                      !_vm.allSupervised
                        ? _c("vs-switch", {
                            model: {
                              value: _vm.showMissingSupervisorRooms,
                              callback: function ($$v) {
                                _vm.showMissingSupervisorRooms = $$v
                              },
                              expression: "showMissingSupervisorRooms",
                            },
                          })
                        : _vm._e(),
                      !_vm.allSupervised
                        ? _c("label", { staticClass: "text-sm sm:text-base" }, [
                            _vm._v(_vm._s(_vm.$t("sem-supervisor"))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("vs-divider", { staticClass: "w-full" }),
              _c(
                "div",
                { class: _vm.gridClasses },
                _vm._l(this.visibleRooms, function (room) {
                  return _c(
                    "vx-card",
                    {
                      key: room.id,
                      staticClass:
                        "w-auto shadow-drop border-solid border-grey border ProctoringMonitorCard",
                      attrs: {
                        headerStyle:
                          "w-full text-left font-bold text-white pb-4",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-2 flex items-center justify-between",
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(room.classroom_identifier)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full flex flex-shrink-0 flex-wrap justify-between items-center",
                                  },
                                  [
                                    _c("label", { staticClass: "text-sm" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "room-users_amount-participantes",
                                              [room.users_amount]
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    !room.supervised
                                      ? _c(
                                          "div",
                                          { staticClass: "flex gap-2" },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "AlertTriangleIcon",
                                                svgClasses: "w-4 h-4",
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "float-right text-danger text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("sem-supervisor-0")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex w-full py-2 justify-center" },
                        [
                          _c(
                            "vs-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.enterRoom(room)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("entrar")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("edu-placeholder-action", {
            staticClass: "my-32",
            attrs: {
              label: _vm.$t("ainda-nao-existem-salas-para-esse-questionario"),
            },
            on: {
              action: function ($event) {
                return _vm.loadRooms(_vm.questionnaireId)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }