<template>
  <div>
    <vx-card v-if="rooms.length > 0">
      <div class="w-full sm:flex-row flex-col my-4 gap-2 flex items-center justify-between">
        <h4>{{ $t('questionnaire_title') }} {{ firstConfig && firstConfig.questionnaire.id }} - 
          "<b>{{ firstConfig && firstConfig.questionnaire.name }}</b>"
        </h4>
        </h4>
        <div class="w-10 h-10 loading-element block relative"></div>
        <div class="flex flex-row gap-2 items-center">
          <label class="text-sm sm:text-base">{{ $t('todas') }}</label>
          <vs-switch v-if="!allSupervised" v-model="showMissingSupervisorRooms"/>
          <label v-if="!allSupervised" class="text-sm sm:text-base">{{ $t('sem-supervisor') }}</label>
        </div>
      </div>
      <vs-divider class="w-full"/>
      <div :class="gridClasses">
        <vx-card
          :key="room.id"
          v-for="room in this.visibleRooms"
          class="w-auto shadow-drop border-solid border-grey border ProctoringMonitorCard"
          headerStyle="w-full text-left font-bold text-white pb-4"
        >
          <template v-slot:header>
            <div class="pt-2 flex items-center justify-between">
              <label>{{ room.classroom_identifier }}</label>
            </div>
          </template>
          <div class="flex w-full py-2 justify-center">
            <vs-button @click="enterRoom(room)">{{ $t('entrar') }}</vs-button>
          </div>
          <template v-slot:footer>
            <div class="w-full flex flex-shrink-0 flex-wrap justify-between items-center">
              <label class="text-sm">
                {{ $t('room-users_amount-participantes', [room.users_amount]) }}
              </label>
              <div class="flex gap-2" v-if="!room.supervised">
                <feather-icon icon="AlertTriangleIcon" class="text-danger" svgClasses="w-4 h-4"/>
                <label class="float-right text-danger text-sm">
                {{ $t('sem-supervisor-0') }}
                </label>  
              </div>
              
            </div>
          </template>
        </vx-card>
      </div>
    </vx-card>
    <edu-placeholder-action
      v-else
      :label="$t('ainda-nao-existem-salas-para-esse-questionario')"
      @action="loadRooms(questionnaireId)"
      class="my-32"
    />
  </div>
</template>

<script>
import ProctoringService from '@/services/api/ProctoringService'
import FeatherIcon from '@/components/FeatherIcon.vue'
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'
import {NotificationActionCodes} from '@/util/Exceptions'

export default {
  components: { FeatherIcon, EduPlaceholderAction },

  props: {
    questionnaireId: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    service: null,
    rooms: [],
    configs: [],
    grid: {
      cols: null
    },
    showMissingSupervisorRooms: false,
    refreshInterval: null,
    loading: false
  }),

  computed: {
    gridClasses() {
      const display = 'grid w-full'
      const gap = 'gap-4'
      const cols = `grid-cols-${this.grid.cols || this.gridCols()}`

      return `${display} ${gap} ${cols}`
    },
    visibleRooms() {
      return this.rooms.filter(
        (room) => {
          return this.showMissingSupervisorRooms ?
            (!room.supervised) : true
        }
      )
    },
    breakpoint() {
      return this.$store.getters.windowBreakPoint
    },
    firstConfig() {
      if (this.configs.length > 0) {
        return this.configs[0]
      }
      return null
    },
    allSupervised() {
      if (this.rooms.length > 0) {
        const findUnsupervisedRoom = this.rooms.findIndex((room) => !room.supervised)
        return findUnsupervisedRoom === -1
      } else {
        return false
      }
    }
  },

  watch: {
    // breakpoint(val) {
    //   this.recomputeGridCols()
    // },
    rooms(rooms) {
      if (this.allSupervised) {
        this.showMissingSupervisorRooms = false
      }
    }
  },

  created() {
    window.addEventListener('resize', this.recomputeGridCols)
    this.service = ProctoringService.build(this.$vs)
    this.loadRooms(this.questionnaireId, true)
    this.refreshInterval = setInterval(() => {
      if (!this.loading) {
        this.loadRooms(this.questionnaireId, false, 'loading-element')
      }
    }, 5000)
  },

  destroyed() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  },

  methods: {
    loadRooms(questionnaireId, showLoading = true, loadingElement = null) {

      if (showLoading && loadingElement === null) this.$vs.loading()

      const refreshIndicatorElement = this.$utils.browser.getElementByClass(loadingElement)
      if (loadingElement !== null && refreshIndicatorElement) {
        this.$vs.loading({
          container: refreshIndicatorElement,
          scale: 0.8
        })
      }

      this.loading = true
      this.service.loadRooms(questionnaireId).then(
        data => {
          if (showLoading && loadingElement === null) this.$vs.loading.close()
          if (loadingElement !== null && refreshIndicatorElement) this.$vs.loading.close(refreshIndicatorElement)
          this.rooms = data.classrooms
          this.configs = data.configs
          this.loading = false
        },
        error => {
          if (showLoading && loadingElement === null) this.$vs.loading.close()
          if (loadingElement !== null && refreshIndicatorElement) this.$vs.loading.close(refreshIndicatorElement)
          this.$utils.serverError.onCodeDo(NotificationActionCodes.GOBACK, error,
            (errorCode) => {
              this.$router.back()
            },
            (notErrorCode) => {
              this.notifyError(this.$vs, this.$tc('proctoring.room.load_failed', 2), 4000)
            }
          )
          this.loading = false
        }
      )
    },
    enterRoom(room) {
      this.$router.push(`/proctoring_room/${room.id}`)
    },
    recomputeGridCols() {
      this.grid.cols = this.gridCols()
    },
    gridCols() {
      const colLimit = this.$utils.reactive.breakpointColsMax()
      const colMinimum = this.$utils.reactive.breakpointColsMin()
      return this.$utils.reactive.colsForTotal(this.rooms.length, colLimit, colMinimum)
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.recomputeGridCols)
  }


}
</script>

<style lang="scss">
  .ProctoringMonitorCard .vx-card__header {
    padding-top: 0.5rem;
    background: padding-box linear-gradient(218deg,#00eef6,#00dde6 50%,#31acff);
    border-radius: 0.4rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .ProctoringMonitorCard .vx-card__footer {
    border-top: 1px solid #b8c2cc80;
    padding: 0.25rem 0.50rem 0.50rem 0.50rem;
  }

  // button.primaryGradient {
  //   // background: linear-gradient(218deg,#31acff,#00dde6 50%,#31acff) !important;
  // }
</style>